.fullCustomcalendar {
    table-layout: fixed;
    word-wrap: break-word;
}

.fullCustomcalendar-table-bordered {
    border: 1px solid #dee2e6;
}

.p-0 {
    padding: 0 !important;
}

.fullCustomcalendar-table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.fullCustomcalendar-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
}

.dailyViewhoursList {
    padding: 0 !important;
}

table {
    border-collapse: collapse;
}

.fullCustomcalendar-table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.fullCustomcalendar-table td,
.fullCustomcalendar-table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.text-white {
    color: #fff !important;
}

.fullCustomcalendar-table th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

.fullCustomcalendar .eventContainer {
    padding: 5px;
}

.fullCustomcalendar .eventContainer {
    padding: 5px;
}

.fullCustomcalendar .customEventDiv>div {
    font-size: 12px;
}

.fullCustomcalendar .eventContainer {
    padding: 5px;
    padding-bottom: 50px;

}

.fullCustomcalendar .eventContainer .eventwrapper:last-child {
    margin-bottom: 0 !important;
}

.fullCustomcalendar {
    table-layout: fixed;
    word-wrap: break-word;
}

.MonthlyEventsContainer {
    min-height: 100px;
}

/* weekly View List start*/
.eventContainer.weeklyViewList:not(:last-child) {
    border-right: 1px solid #ddd;
}

/* weekly View List end*/

/* dailylisthourview  start*/

.fullCustomcalendar-slats .fc-minor td {
    border-top-style: dotted;
}

.fc-ltr .fc-axis {
    text-align: right;
}

.fullCustomcalendar-slats .fc-axis {
    vertical-align: middle;
    padding: 0 4px;
    white-space: nowrap;
}

.fullCustomcalendar-slats td {
    height: 1.5em;
    border-bottom: 0;
}

.fullCustomcalendar-slats th,
.fullCustomcalendar-slats td {
    /* border-style: solid; */
    border-width: 1px;
    padding: 0;
    vertical-align: top;
}

.fullCustomcalendar-slats .fc-axis {
    border-right: 1px solid #ddd;

}


/* dailylisthourview  end*/
/* weeklyViewhoursList  start*/

.eventContainer.weeklyViewhoursList:not(:last-child) {
    border-right: 1px solid #ddd;
}

.eventContainer.weeklyViewhoursList {
    padding: 0 !important;
}

.weeklyviewhourslisttr .fc-axis.fc-time.fc-widget-content {
    padding: 0 !important;

}

.weeklyviewhourslisttr .fc-axis {
    border-right: 1px solid #ddd;
}

.weeklyviewhourslisttr .fc-axis {
    vertical-align: middle;
    padding: 0 4px;
    white-space: nowrap;
}

.weeklyviewhourslisttr td {
    height: 1.5em;
    border-bottom: 0;
}

/* weeklyViewhoursList  end*/
.renderResourceViewDay .adjustWidth,
.ResourceViewWeekListEmployee {
    width: 25px;
    vertical-align: middle;
    height: 2.3rem;
    text-align: center;


}

.renderResourceViewDay .adjustWidth,
.ResourceViewWeekList .adjustWidth {
    height: 33.85px;

}

.adjustWidthAccordingtoResource {
    height: 33.85px !important;

}

.ResourceViewWeekList {
    vertical-align: middle !important;
    height: 2.3rem;
    text-align: center;
}

.renderResourceViewDay .adjustWidth span {
    font-size: 13px;
}

.renderResourceViewDay .adjustWidth:not(:last-child),
.ResourceViewWeekList .header,
.ResourceViewWeekListEmployee,
.ResourceViewMonth .header,
.ResourceViewMonthEmployee {
    border-right: 1px solid #ddd;

}

.renderResourceViewDay .adjustWidth,
.ResourceViewWeekList .header,
.ResourceViewMonth .header {
    border-bottom: 1px solid #ddd;

}

.renderResourceViewDay .overflow-scroll,
.ResourceViewMonth .overflow-scroll {
    overflow: auto;
}

.spacer {
    height: 15px;
}

.ResourceViewWeekList.text-dark .text-white {
    color: #000 !important;
}

.ResourceViewMonth .header {
    border-bottom: 1px solid #ddd;
    width: 25px;
    vertical-align: middle;
    height: 2.3rem;
    text-align: center;
}

.ResourceViewMonthEmployee {
    height: 1.5rem;
}

.ResourveViewEmployeeTab .spaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.makeFlexColumn {
    display: flex;
    flex-wrap: wrap;
}

.ResourveViewEmployeeTab button {
    background-color: #1abc9c;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid #1abc9c;
    padding: 0.25rem 0.5rem;
    font-size: .7rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.width-25 {
    width: 25%;
}

.width-75 {
    width: 75%;
}

.ResourveViewEmployeeTab .header,
.ResourceBody {
    padding: 4px !important;
    border-top: none !important;
}

.padding-right-2 {
    padding-right: 15px;
}

.badge-success {
    color: #fff;
    background-color: #28a745;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.noBorder {
    border-color: transparent !important;
}

.pad-8 {
    /* padding: 8px !important; */
    padding: 4.5px !important;
}

.DatesTD {
    border-right: 1px solid #ddd;

}

.weeklyViewList:not(.notremove),
.dailyViewList {
    border-top: none !important;
}

.showTitleRow td {
    border-top: none !important;
    border-right: 1px solid #ddd;
}

.showTitleRow .titleTd {
    font-weight: 900;
    text-align: left;
    font-size: 16px;
}

.gearIconOnEvent {
    position: absolute;
    left: 3px;
    top: 0;
    color: #fff !important;
    font-size: 12px;
    display: none;
}

.gearIconOnEvent.paperclip {
    left: unset;
    right: 0px;
}

.eventwrapper {
    position: relative;
    cursor: pointer;
}

.eventwrapper:hover .gearIconOnEvent {
    display: block;
}

.showNoteHover {
    color: #fff !important;
    text-decoration: none !important;
    cursor: pointer;
}

.showNoteHover:hover svg {
    display: inline-block !important;
}

.isholiday {
    color: #2499BA !important;
}

.HoverBoxforAddingDiv {
    min-height: 50px;
    border: 2px solid #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    visibility: hidden;
    opacity: 0;
}

.HoverBoxforAddingDiv>span {
    font-size: 25px;
    color: #ccc;
    margin-bottom: 4px;

}

.showBOx:hover .HoverBoxforAddingDiv {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
}

.weeklyViewList {
    min-height: 50px;
}

.eventwrapper.resourceViews {
    height: 31.85px;
    opacity: .75;
}

.eventwrapper.resourceViews .adjustPaddingOfEvent {}

.fc-title-with-tags {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ResourceMainTd:empty:hover {
    background-color: #EEEEEE;
    opacity: 0.8;
    cursor: pointer;
}

.ResourceViewWeekList.header a {
    color: #000 !important;
}

.resourceWeek.WeekEnd,
.ResourceViewMonth.WeekEnd {
    background-color: #EEEEEE;
}

.resourceWeek.WeekEnd:not(:last-child) {
    border-left: 2px solid grey;

}

.ResourceViewMonth.header>div {
    width: 120px;
}

.selectedEvent {
    background-color: #007bff !important;
}

.eventContainer.weeklyViewhoursList {
    position: relative;
}

.eventContainer.weeklyViewhoursList .eventwrapper {
    position: absolute;
}





.ShowNote .badge {
    white-space: break-spaces;
}


.addDND {
    position: relative;
}

.addDND .eventwrapper {
    position: absolute;
    margin-bottom: 0 !important;
    overflow: hidden;
    z-index: 1;
    left: 0;
    right: 0;

}

.addDND .eventwrapper:hover {
    z-index: 1000 !important;
}

.addDND .event-div {
    pointer-events: none;
}

.ResizeEvent {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    overflow: hidden;
    line-height: 8px;
    color: #fff;
    font-size: 13px;
    font-family: monospace;
    text-align: center;
    cursor: s-resize;
}

.ResizeEvent:after {
    content: "=";
}

.eventwrapper.resize {
    border: 3px solid #000;
}

.Nopointer-events {
    pointer-events: none;
}

.yellowmark {
    background-color: yellow;
}