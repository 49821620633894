body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ResponseVariableName {
  font-size: 13;
  margin-top: 2px;
}

.CustomInputClassforPhone {
  height: calc(3.5rem + 2px) !important;
  line-height: 1.25 !important;
  padding-top: 22px;

}

.forPhone {
  position: absolute;
  top: 8px;
  left: 45px;
  opacity: .65;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

div.ListItem.active {
  background-color: #4170fb;
}

div.ListItem.active>li>div {
  color: white;
}

.pointer-event {
  cursor: pointer;
}

.company-primary-info {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
}

.avatar.avatar-l {
  flex: 0 0 65px;
  font-size: 20px;
  height: 65px;
  line-height: 65px;
  width: 65px;
}

.avatar {
  background-color: #ececec;
  border-radius: 50%;
  color: #2c3e50;
  display: inline-block;
  font-weight: 700;
  height: 37px;
  line-height: 37px;
  text-align: center;
  width: 37px;
}

.darColor {
  color: #6f727d;

}

.timeline.timeline-3 .timeline-items .timeline-item .timeline-media img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 100%;
}

.timeline.timeline-3 .timeline-items .timeline-item {
  margin-left: 25px;
  border-left: 2px solid #EBEDF3;
  padding: 0 0 20px 50px;
  position: relative;
}

.timeline.timeline-3 .timeline-items .timeline-item .timeline-media {
  position: absolute;
  top: 0;
  left: -26px;
  border: 2px solid #EBEDF3;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  line-height: 0;
}

.timeline.timeline-3 .timeline-items {
  margin: 0;
  padding: 0;
}

.cardtimiline {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  width: 100% !important;
}

.para_des:hover .leaddes {
  display: inline-block !important;

}

.form-group {
  margin-bottom: 1rem;
}

.fc .fc-col-header-cell-cushion {
  color: #898b96;
  text-decoration: none !important;

}

.fc a {
  color: initial;
  text-decoration: none !important;

}

.sticky-sidebar {
  position: sticky;
  top: 0;
}

div.fade.modal-backdrop.show {
  z-index: 1200;
}

div.fade.modal.show {
  z-index: 1201;
}

.rotate90deg {
  transform: rotate(90deg);
}

.check-select .dropdown-toggle {
  background-color: #fff !important;
  border: 1px solid #c3c5d1 !important;
  width: 115px;
  border-radius: 7px !important;
  color: #000 !important;
  padding: 4px 20px 4px 8px !important;
  font-size: 13px !important;
  position: relative;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-toggle::after {
  border-top: 5px solid !important;
  border-right: 5px solid transparent !important;
  border-left: 5px solid transparent !important;
  margin-right: 3px;

}

.dropdown-item:active {
  background-color: #ffffff00 !important;
  color: #212529 !important;
}

.check-select .dropdown-toggle:after {
  position: absolute;
  top: 12px;
  right: 5px;
}

.check-select .dropdown-item {
  font-size: 13px !important;
}

.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item:focus {
  background-color: #00c1950d !important;
}

.dropdown-item.choose-all:active,
.dropdown-item.show-item:active {
  background-color: #ffffff00 !important;
}

.dropdown-item.choose-all:hover,
.dropdown-item.show-item:hover {
  background-color: #ffffff00 !important;
  cursor: default !important;
}

.check-select.autoWidth .dropdown-toggle {
  width: auto !important;
  height: 35px !important;
  display: flex;
  align-items: center;
}

.check-select.autoWidth .dropdown-toggle::after {
  display: none !important;
}

/* @media only screen and (min-width:786px){
  .col-sidebar{
    width:22% !important;
  }
  .col-main-content{
    width:78% !important;
  }
} */
.max250px {
  max-width: 250px;

}

.max275px {
  max-width: 275px;
}


.breakSpaceClass>span {
  /* white-space: break-spaces; */
}

.col-main-content {
  width: 80%;
}

.btn-group.btn-group-sm.btn-block {
  width: 100%;
}

.btn-group.btn-group-sm.btn-block>button {
  display: block;
}

.btnDDList+.dropdown-menu.show {
  min-width: 300px;
}

.ps-sidebar-root {
  border-color: transparent !important;
}

.InlineDatePickerContainer {
  margin-left: -4px !important;
}

.testHeader .fc-datagrid-cell-cushion {
  width: 100%;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-15px {
  font-size: 15px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.custom-tabs-font .nav-tabs li,
.custom-tabs-font .nav-tabs li .nav-link.active {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.ex-imp-tab-font-fix span,
.subs-tab-font-fix span,
.ex-imp-tab-font-fix .form-check-label,
.subs-tab-font-fix .form-check-label,
.ex-imp-tab-font-fix p,
.subs-tab-font-fix p {
  font-size: 14px !important;
}

.ex-imp-tab-font-fix span.textdesginHead {
  font-size: 16px !important;
}